import React, { useEffect } from 'react'
import contactMoji from '../assets/contactMoji.svg'
import { BsInstagram, BsGithub } from 'react-icons/bs'
import { FaFigma } from 'react-icons/fa'
import { AiOutlineLinkedin } from 'react-icons/ai'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const leetcode = <svg className='hover:opacity-50' width="36px" height="34px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#6DFACD">
    <path d="M13.483 0a1.374 1.374 0 0 0-.961.438L7.116 6.226l-3.854 4.126a5.266 5.266 0 0 0-1.209 2.104 5.35 5.35 0 0 0-.125.513 5.527 5.527 0 0 0 .062 2.362 5.83 5.83 0 0 0 .349 1.017 5.938 5.938 0 0 0 1.271 1.818l4.277 4.193.039.038c2.248 2.165 5.852 2.133 8.063-.074l2.396-2.392c.54-.54.54-1.414.003-1.955a1.378 1.378 0 0 0-1.951-.003l-2.396 2.392a3.021 3.021 0 0 1-4.205.038l-.02-.019-4.276-4.193c-.652-.64-.972-1.469-.948-2.263a2.68 2.68 0 0 1 .066-.523 2.545 2.545 0 0 1 .619-1.164L9.13 8.114c1.058-1.134 3.204-1.27 4.43-.278l3.501 2.831c.593.48 1.461.387 1.94-.207a1.384 1.384 0 0 0-.207-1.943l-3.5-2.831c-.8-.647-1.766-1.045-2.774-1.202l2.015-2.158A1.384 1.384 0 0 0 13.483 0zm-2.866 12.815a1.38 1.38 0 0 0-1.38 1.382 1.38 1.38 0 0 0 1.38 1.382H20.79a1.38 1.38 0 0 0 1.38-1.382 1.38 1.38 0 0 0-1.38-1.382z" />
</svg>


const Contact = () => {

    const [ref, inView] = useInView({ triggerOnce: true, });
    const animation = useAnimation();

    useEffect(() => {
        if (inView) {
            animation.start({
                y: 0, transition: { duration: 0.7, ease: 'easeInOut' }
            })
        }
        if (!inView) {
            animation.start({
                y: 300
            })
        }
    }, [inView]);

    return (
        <div name="contact" className='relative w-full h-screen px-4 py-20 bg-white lg:px-20 md:py-40 lg:py-20'>
            <div className='w-[70%] h-[100vh] rounded-br-full bg-[#CCECE7] absolute top-0 blur-2xl mix-blend-multiply left-0'></div>
            <div className='w-[70%] h-[100vh] rounded-tl-full bg-[#CAD2F9] absolute bottom-0 mix-blend-multiply blur-2xl opacity-75 right-0'></div>

            <motion.div ref={ref} animate={animation} className='w-full h-full p-4 md:p-10 lg:p-14 bg-[#121316] relative'>
                <div className='grid w-full h-full md:grid-cols-2 font-moskSemi'>
                    <div className="grid place-items-center">
                        <div className='pt-2 pl-2 text-gray-100 h-fit'>
                            <h1 className='text-3xl sm:text-4xl xl:text-5xl text-[#6DFACD] pb-4'>I'm always up for a chat</h1>
                            <p className='text-xl underline lg:text-2xl '>Pop me an e-mail</p>
                            <p className='flex pt-3 text-xl lg:text-2xl'>at <a href="mailto:shashant.dudam@gmail.com? subject=subject text" className='pl-2 hover:underline decoration-[#6dfacd]'> shashant.dudam@gmail.com</a> </p>
                            <p className='pt-3 text-xl lg:text-2xl '>or you can ping me on my social’s.</p>
                            <div className='flex gap-x-4 text-[#6DFACD] mt-4 md:mt-8'>
                                <a href='https://www.linkedin.com/in/shashant-dudam-9332a7214/' rel="noreferrer" target='_blank'><AiOutlineLinkedin size={36} className=' hover:opacity-50' /></a>
                                <a href='https://github.com/Sad7920' rel="noreferrer" target='_blank'><BsGithub size={36} className=' hover:opacity-50' /></a>
                                <a href='https://www.instagram.com/shashantttt/' rel="noreferrer" target='_blank'><BsInstagram size={36} className=' hover:opacity-50' /></a>
                                <a href='https://leetcode.com/u/shashant-dudam/' rel="noreferrer" target='_blank'>{leetcode}</a>
                            </div>
                        </div>
                    </div>
                    <div className='grid place-items-center'>
                        <img src={contactMoji} alt='contact-moji' className=' w-96' />
                    </div>
                </div>
                {/* <h1 className='text-[#6dfacd] text-5xl md:text-6xl font-moskUltra text-shadow-md absolute -bottom-5'>Hey There!</h1> */}
            </motion.div>
        </div>
    )
}

export default Contact